<template>
  <div id="app">
    <div v-if="!locked">
      <Nav />
      <router-view />
    </div>
    <div
      v-if="locked"
      class="d-flex w-100 vh-100 align-items-center justify-content-center"
    >
      <div class="p-3">
        <form @submit="unlock">
          <input
            type="password"
            class="form-control form-control-lg"
            placeholder="Passwort eingeben..."
            value="rotary"
            v-model="password"
            autofocus
          />
          <button
            class="btn btn-primary btn-lg w-100 mt-1"
            type="submit"
            :disabled="!unlockAvailable"
          >
            Entsperren
          </button>
        </form>
      </div>
    </div>
    <div class="text-center w-100 py-2 text-muted small">
      version 0.2.5<br />{{ user.token }}
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Nav from "@/components/Nav.vue"; // @ is an alias to /src

export default Vue.extend({
  data() {
    return {
      authenticated: false,
      unlockAvailable: false,
      password: "",
      interval: null as any,
    };
  },
  components: {
    Nav,
  },
  computed: {
    locked(): boolean {
      return !this.authenticated;
    },
    user(): any {
      return this.$store.state.user;
    },
    settings(): any {
      return this.$store.state.settings;
    },
  },
  methods: {
    unlock(e): void {
      e.preventDefault();
      if (this.unlockAvailable === false) return;
      if (this.password === this.settings.master_password) {
        localStorage.setItem("master_token", this.settings.master_password);
        this.authenticated = true;
        this.$store
          .dispatch("getUser")
          .then((response) => {
            this.$router.push("/events").catch((e) => {
              //
            });
          })
          .catch((e) => {
            console.error(e);
            this.$router.push("/").catch((e) => {
              //
            });
          });
      }
      this.password = "";
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted() {
    try {
      Notification.requestPermission().then((perm) => {
        if (perm === "granted") {
          console.log("Notification permission granted.");
        }
      });
    } catch (e) {
      console.error(e);
    }
    const notifiedEventIds = localStorage.getItem("notified_event_ids")
      ? JSON.parse(localStorage.getItem("notified_event_ids") as string)
      : [];
    console.log("notifiedEventIds", notifiedEventIds);
    let notification;
    this.interval = setInterval(() => {
      this.$store.dispatch("getEvents");
      const events = this.$store.state.events;
      if (events.length) {
        const upcomingEvent = events[0];
        // check if event is in 1 day
        const oneDay = 1000 * 60 * 60 * 24;
        const eventDate = new Date(upcomingEvent.starts_at);
        const now = new Date();
        const diff = eventDate.getTime() - now.getTime();
        if (diff < oneDay * 3 && !notifiedEventIds.includes(upcomingEvent.id)) {
          // event is in 3 days
          const humanReadableDate = eventDate.toLocaleDateString("de-DE", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          });
          localStorage.setItem(
            "notified_event_ids",
            JSON.stringify([...notifiedEventIds, upcomingEvent.id])
          );
          try {
            // send this notification once
            notification = new Notification("Rotary Neuruppin", {
              body: `Nimmst Du teil?\nEvent: "${upcomingEvent.title}" am ${humanReadableDate}.`,
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
    }, 1000 * 5);
    /*
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      } else {
        if (notification) {
          notification.close();
        }
        if (this.interval) {
          clearInterval(this.interval);
        }
      }
    });
    */

    this.authenticated = false;

    this.$store
      .dispatch("getSettings")
      .then(() => {
        this.unlockAvailable = true;
        const masterToken = localStorage.getItem("master_token");
        if (masterToken === this.settings.master_password) {
          this.$store.dispatch("getUser");
          this.authenticated = true;
          this.$router.push("/events").catch((e) => {
            //
          });
        }
      })
      .catch(console.error);
  },
});
</script>
