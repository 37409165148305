import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { default as dayjs } from "dayjs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    users: [],
    events: [],
    allEvents: [],
    votes: [],
    settings: {},
  },
  getters: {
    activeEvent(state) {
      const filtered = state.events.filter((event) => {
        console.log(dayjs().diff(dayjs((event as any).starts_at), "hour"));
        return (
          dayjs().diff(dayjs((event as any).starts_at), "hour") >= 0 &&
          dayjs().diff(dayjs((event as any).starts_at), "hour") < 6
        );
      });
      if (filtered.length) {
        return filtered[0];
      }
      return null;
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_ALL_EVENTS(state, events) {
      state.allEvents = events;
    },
    SET_VOTES(state, votes) {
      state.votes = votes;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    login(context, payload): Promise<void> {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/login`, {
            data: {
              email: payload.email,
              password: payload.password,
            },
          })
          .then((response) => {
            context.commit("SET_USER", {
              token: response.data.data.token,
              id: response.data.data.id,
              name: response.data.data.name,
              email: response.data.data.email,
            });
            localStorage.setItem("token", response.data.data.token);
            resolve();
          })
          .catch(reject);
      });
    },
    register(context, payload): Promise<void> {
      console.log(process.env);
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_BASE_URL}/register`, {
            data: {
              name: payload.name,
              email: payload.email,
              password: payload.password,
            },
          })
          .then((response) => {
            context.commit("SET_USER", {
              token: response.data.data.token,
              id: response.data.data.id,
              name: response.data.data.name,
              email: response.data.data.email,
            });
            localStorage.setItem("token", response.data.data.token);
            resolve();
          })
          .catch(reject);
      });
    },
    getSettings(context): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/settings`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            context.commit("SET_SETTINGS", response.data.data);
            resolve();
          })
          .catch(reject);
      });
    },
    getVotes(context): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/votes`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            context.commit("SET_VOTES", response.data.data);
            resolve();
          })
          .catch(reject);
      });
    },
    getEvents(context): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/events`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            context.commit("SET_EVENTS", response.data.data);
            resolve();
          })
          .catch(reject);
      });
    },
    getAllEvents(context): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/events?all=true`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            context.commit("SET_ALL_EVENTS", response.data.data);
            resolve();
          })
          .catch(reject);
      });
    },
    getUser(context): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/user`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            context.commit("SET_USER", {
              token: response.data.data.token,
              id: response.data.data.id,
              name: response.data.data.name,
              email: response.data.data.email,
            });
            resolve();
          })
          .catch(reject);
      });
    },
    getUsers(context): Promise<void> {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        axios
          .get(`${process.env.VUE_APP_API_BASE_URL}/users`, {
            headers: {
              Authorization: `Token ${token}`,
            },
          })
          .then((response) => {
            context.commit("SET_USERS", response.data.data);
            resolve();
          })
          .catch(reject);
      });
    },
    eventAction(
      context,
      payload: { eventId: number; type: "accept" | "cancel" | "checkIn", plus?: number }
    ) {
      const token = localStorage.getItem("token");
      axios
        .put(
          `${process.env.VUE_APP_API_BASE_URL}/events/${payload.eventId}/${payload.type}`,
          { plus: payload.plus },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          context.commit("SET_EVENTS", response.data.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    voteAction(
      context,
      payload: {
        voteId: number;
        type: "agree" | "disagree" | "abstain" | "reset";
      }
    ) {
      const token = localStorage.getItem("token");
      axios
        .put(
          `${process.env.VUE_APP_API_BASE_URL}/votes/${payload.voteId}/${payload.type}`,
          {},
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          context.commit("SET_VOTES", response.data.data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  modules: {},
});
