<template>
  <div class="px-1 py-1 bg-dark text-white">
    <div class="container-fluid">
      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <ul
          class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small"
        >
          <li>
            <router-link
              to="/documents"
              class="nav-link text-secondary"
              exact-active-class="text-white"
            >
              <i
                class="bi bi-file-earmark-text d-block mx-auto mb-1"
                style="font-size: 1.5rem"
              ></i>

              Dokumente
            </router-link>
          </li>

          <li class="pt-2">
            <router-link
              to="/events"
              class="nav-link text-secondary"
              exact-active-class="text-white"
            >
              <img
                src="../assets/logo.png"
                width="24px"
                class="d-block mx-auto mb-2"
              />
              Events
            </router-link>
          </li>

          <li>
            <router-link
              to="/votes"
              class="nav-link text-secondary"
              exact-active-class="text-white"
            >
              <i
                class="bi bi-megaphone d-block mx-auto mb-1"
                style="font-size: 1.5rem"
              ></i>

              Abstimmen
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "Nav",
});
</script>
<style lang="scss">
.nav {
  text-align: center;
}
</style>
